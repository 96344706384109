import React, { ChangeEvent, FC, HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import pick from 'lodash/pick'
import { Layout } from 'antd'
import { EditOutlined, LoadingOutlined } from '@ant-design/icons'
import confirm from 'antd/lib/modal/confirm'
import {
  Avatar,
  Badge,
  Button,
  Dropdown,
  Form,
  Input,
  PageHeader,
  SecondaryLink,
  SecondaryText,
  Space,
  Spin,
  Tooltip,
  UploadFile
} from '~/core-components'
import { MenuAnchors, ImageAuth, DocumentTitle, UploadPhotoModal, ErrorBoundary, Audit } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { fetchMasters } from '~/features/master'
import { PayRecurrings } from '~/features/payroll/containers/PayRecurring/PayRecurrings'
import { ActivateEmployeeTag, selectMyTenants } from '~/features/iam'
import { FeatureFlag, Permission, PermissionAction } from '~/constants'
import { EMP_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { ActionResult } from '~/types/store'
import { MenuItem } from '~/types/common'
import { getBaseUrl } from '~/utils'
import { EmployeeBasic } from './components/EmployeeBasic'
import { EmployeePersonal } from './components/EmployeePersonal'
import { EmployeeHire } from './components/EmployeeHire'
import { EmployeeProbation } from './components/EmployeeProbation'
import { EmployeeRetirement } from './components/EmployeeRetirement'
import { EmployeePayment } from './components/EmployeePayment'
import { EmployeeStatutory } from './components/EmployeeStatutory'
import { EmStatutoryExempt } from './components/EmStatutoryExempt'
import { EmployeeEmergency } from './components/EmployeeEmergency'
import { EmIdentities } from './components/EmIdentities'
import { EmAddresses } from './components/EmAddresses'
import { EmCalendars } from './components/EmCalendars'
import { EmCareer } from './components/EmCareer'
import { EmSalaries } from './components/EmSalaries'
import { EmPayGroups } from './components/EmPayGroups'
import { EmLeaveGroups } from './components/EmLeaveGroups'
import { EmFamilies } from './components/EmFamilies'
import { EmEducations } from './components/EmEducations'
import { EmDocuments } from './components/EmDocuments'
import { ReactComponent as BriefcaseOutline } from '../../../../svgs/briefcase-outline.svg'
import {
  deleteEmployee,
  deleteEmployeePhoto,
  fetchEmDepartments,
  fetchEmJobs,
  uploadEmployeePhoto
} from '../../actions'
import { selectEmJobMaster, selectEmDepartmentMaster, selectEmHires } from '../../selectors'
import { useIsPermittedEmployeePay, useIsPermittedEmployeeLve, useIsPermittedEmployeeAtt } from '../../hooks'
import { fetchEmHires } from '../../actions'
import { useEmployee } from '../../hooks'
import { SelectEmHireDrawer } from './components/SelectEmHireDrawer'
import { ResetPasswordTag } from '~/features/iam/containers/ResetPasswordTag'
import { EmLocations } from './components/EmLocations'
import { EmAttendanceGroups } from './components/EmAttendanceGroups'
import { EmShiftRoles } from './components/EmShiftRoles'
import { EmLeaveBuddies } from './components/EmLeaveBuddies'
import { EmployeeAttendance } from './components/EmployeeAttendance'
import './Employee.less'

const { Content, Sider } = Layout

const routes = [
  {
    path: EMP_ROUTES.directory,
    breadcrumbName: 'Directory'
  },
  {
    path: '',
    breadcrumbName: 'People info'
  }
]

interface EmployeeProps {}

interface EmployeeParams {
  id: string
}

interface EmHireDrawerState {
  visible: boolean
}

const DEFAULT_EM_HIRE_STATE: EmHireDrawerState = { visible: false }

const activeTenantCode = localStorage.getItem('tenant')
const baseUrl = getBaseUrl('/filestore')

const getMenuList = (
  prefix: string,
  isPermittedEmployeePay: boolean,
  isPermittedEmployeeLve: boolean,
  isPermittedEmployeeAtt: boolean,
  featureFlags?: string[]
) => {
  let menus: MenuItem[] = [
    {
      value: 'Basic information',
      path: `${prefix}#basicinfo`,
      sysPermissionId: Permission.allEmployeeTabs
    },
    {
      value: 'Personal',
      path: `${prefix}#personal`,
      sysPermissionId: Permission.employeePersonalTab
    },
    {
      value: 'Employment',
      path: `${prefix}#employment`,
      sysPermissionId: Permission.employeeEmploymentTab
    },
    {
      value: 'Calendar',
      path: `${prefix}#calendar`,
      sysPermissionId: Permission.employeeCalendarTab
    }
  ]

  if (isPermittedEmployeePay) {
    menus.push({
      value: 'Salary',
      path: `${prefix}#salary`,
      sysPermissionId: Permission.employeeSalaryTab
    })
  }

  menus.push({
    value: 'Payment',
    path: `${prefix}#payment`,
    sysPermissionId: Permission.employeePaymentTab
  })

  if (isPermittedEmployeePay) {
    menus.push({
      value: 'Statutory',
      path: `${prefix}#statutory`,
      sysPermissionId: Permission.employeeStatutoryTab
    })
  }

  if (isPermittedEmployeeLve && featureFlags?.includes(FeatureFlag.Leave)) {
    menus.push({
      value: 'Leave',
      path: `${prefix}#leave`,
      sysPermissionId: Permission.employeeLeaveTab
    })
  }

  if (isPermittedEmployeeAtt && featureFlags?.includes(FeatureFlag.Attendance)) {
    menus.push({
      value: 'Attendance',
      path: `${prefix}#attendance`,
      sysPermissionId: Permission.employeeAttendanceTab
    })
  }

  menus.push(
    {
      value: 'Family',
      path: `${prefix}#family`,
      sysPermissionId: Permission.employeeFamilyTab
    },
    {
      value: 'Emergency contacts',
      path: `${prefix}#emergency`,
      sysPermissionId: Permission.employeeEmergencyTab
    },
    {
      value: 'Education',
      path: `${prefix}#education`,
      sysPermissionId: Permission.employeeEducationTab
    },
    {
      value: 'Documents',
      path: `${prefix}#document`,
      sysPermissionId: Permission.employeeDocumentTab
    }
  )

  return menus
}

export const Employee: FC<EmployeeProps> = () => {
  const { id } = useParams<EmployeeParams>()
  const [employee] = useEmployee(id, 'always')
  const employeeName = employee?.fullName
  const emJobMaster = useSelector(selectEmJobMaster)(id)
  const emDepartmentMaster = useSelector(selectEmDepartmentMaster)(id)
  const [editing, setEditing] = useState(false)
  const [uploading, setUploading] = useState(false)
  const history = useHistory()

  const isPermittedEmployeePay = useIsPermittedEmployeePay(id)
  const isPermittedEmployeeLve = useIsPermittedEmployeeLve(id)
  const isPermittedEmployeeAtt = useIsPermittedEmployeeAtt(id)
  const canViewPersonalTab = usePermissionGate(Permission.employeePersonalTab)
  const canViewEmploymentTab = usePermissionGate(Permission.employeeEmploymentTab)
  const canViewCalendarTab = usePermissionGate(Permission.employeeCalendarTab)
  const canViewSalaryTab = usePermissionGate(Permission.employeeSalaryTab) && isPermittedEmployeePay
  const canViewPaymentTab = usePermissionGate(Permission.employeePaymentTab)
  const canViewStatutoryTab = usePermissionGate(Permission.employeeStatutoryTab) && isPermittedEmployeePay
  const canViewLeaveTab = usePermissionGate(Permission.employeeLeaveTab) && isPermittedEmployeeLve
  const canViewAttendanceTab = usePermissionGate(Permission.employeeAttendanceTab) && isPermittedEmployeeAtt
  const canViewFamilyTab = usePermissionGate(Permission.employeeFamilyTab)
  const canViewEmergencyTab = usePermissionGate(Permission.employeeEmergencyTab)
  const canViewEducationTab = usePermissionGate(Permission.employeeEducationTab)
  const canViewDocumentTab = usePermissionGate(Permission.employeeDocumentTab)

  const canModify = usePermissionGate(Permission.allEmployeeTabs, PermissionAction.Modify)
  const canDelete = usePermissionGate(Permission.employee, PermissionAction.Delete)
  const myTenants = useSelector(selectMyTenants)
  const activeTenant = useMemo(() => myTenants.find(t => t.tenantCode === activeTenantCode), [myTenants])
  const menuList = getMenuList(
    EMP_ROUTES.employee.replace(':id', id),
    isPermittedEmployeePay,
    isPermittedEmployeeLve,
    isPermittedEmployeeAtt,
    activeTenant?.featureFlags
  )
  const emHires = useSelector(selectEmHires)(id)
  const [emHireDrawerState, setEmHireDrawerState] = useState<EmHireDrawerState>(DEFAULT_EM_HIRE_STATE)
  const [uploadModal, setUploadModal] = useState<boolean>(false)

  useEffect(() => {
    dispatch(fetchMasters('job', { strategy: 'when-empty' }))
    dispatch(fetchMasters('department', { strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    if (id) {
      dispatch(fetchEmJobs(id, { strategy: 'when-empty' }))
      dispatch(fetchEmDepartments(id, { strategy: 'when-empty' }))
      dispatch(fetchEmHires(id))
    }
  }, [id])

  const handleEdit = useCallback(() => setEditing(true), [])
  const handleView = useCallback(() => setEditing(false), [])

  const handleDelete = useCallback(() => {
    if (id) {
      const modal = confirm({
        title: 'Delete employee',
        content: (
          <>
            <div>Do you want to delete employee "{employeeName}"?</div>
            <Form.Item
              className="employee__confirm-delete"
              label={
                <>
                  Type "<b>{employeeName}</b>" to confirm
                </>
              }
            >
              <Input
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  modal.update({ okButtonProps: { disabled: event.target.value !== employeeName } })
                }}
              />
            </Form.Item>
          </>
        ),
        onOk: async () => {
          const result: ActionResult | undefined = await dispatch(deleteEmployee(id))
          if (!result?.errors) {
            history.push(EMP_ROUTES.directory)
          }
        },
        okText: 'Delete',
        okType: 'danger',
        okButtonProps: {
          disabled: true
        }
      })
    }
  }, [id, employeeName, history])

  const handleUploadChange = useCallback(
    async (file?: UploadFile) => {
      try {
        setUploading(true)
        if (file) {
          await dispatch(uploadEmployeePhoto(id, file))
        }
      } finally {
        setUploading(false)
        setUploadModal(false)
      }
    },
    [id]
  )

  const handleEmHire = useCallback(() => {
    setEmHireDrawerState({ visible: true })
  }, [])

  const handleEmHireDrawerClose = useCallback(
    (id?: string) => {
      setEmHireDrawerState(DEFAULT_EM_HIRE_STATE)
      if (id) {
        history.push(EMP_ROUTES.employee.replace(':id', id))
      }
    },
    [history]
  )

  const handleShowUploadModal = useCallback(() => {
    setUploadModal(true)
  }, [])

  const handleCloseUploadModal = useCallback(() => {
    setUploadModal(false)
  }, [])

  const handleRemovePhoto = useCallback(async () => {
    try {
      setUploading(true)
      if (id) {
        await dispatch(deleteEmployeePhoto(id))
      }
    } finally {
      setUploading(false)
    }
  }, [id])

  const eventProps = { onEdit: handleEdit, onSave: handleView, onCancel: handleView }

  return (
    <Layout className="employee">
      <Sider className="employee__sider" width={220}>
        <div className="employee__employee">
          <div className="employee__employee-avatar">
            <div className="employee__employee-avatar-info">
              <div>
                {!!employee?.photoId ? (
                  <Avatar
                    size={48}
                    src={
                      <ImageAuth
                        src={`${baseUrl}/file/${employee.photoId}/thumbnailphoto/48`}
                        // src={`/employee/employee/${id}/avatar/48?photo_id=${employee?.photoId}`}
                        width={48}
                        preview={false}
                        defaultImage={<Avatar size={48} icon={<i className="fal fa-user" />} />}
                      />
                    }
                  />
                ) : (
                  <Avatar size={48} icon={<i className="fal fa-user" />} />
                )}
                {canModify && (
                  <div className="employee__employee-pencil-icon">
                    <Dropdown
                      menu={{
                        items: [
                          { key: 'change', label: 'Change profile photo', onClick: handleShowUploadModal },
                          { key: 'remove', label: 'Remove', onClick: handleRemovePhoto, disabled: !employee?.photoId }
                        ]
                      }}
                    >
                      {uploading ? (
                        <Spin size="small" indicator={<LoadingOutlined spin />} />
                      ) : (
                        <SecondaryLink>
                          <EditOutlined />
                        </SecondaryLink>
                      )}
                    </Dropdown>
                  </div>
                )}
              </div>
              <div className="employee__employee-info">
                <h1>{employee?.fullName}</h1>
                {emJobMaster.jobName && <SecondaryText>{emJobMaster.jobName}</SecondaryText>}
                {emDepartmentMaster.departmentName && (
                  <SecondaryText>{emDepartmentMaster.departmentName}</SecondaryText>
                )}
                <ActivateEmployeeTag employeeId={employee?.id || ''} />
                <ResetPasswordTag employeeId={employee?.id || ''} />
              </div>
            </div>
          </div>
        </div>
        <MenuAnchors menus={menuList} containerId="em-content" targetOffset={50} />
      </Sider>
      <Content id="em-content" className="employee__content">
        <DocumentTitle title="People Info" />
        <PageHeader
          title="People info"
          containerId="em-content"
          breadcrumb={{ routes }}
          extra={
            <Space key="1">
              {canDelete && editing ? <Button onClick={handleDelete}>Delete</Button> : null}
              {emHires.length > 1 && (
                <Badge count={emHires.length} offset={[3, 10]} className="hires">
                  <Tooltip title="Multiple hires">
                    <Button icon={<BriefcaseOutline width={18} height={18} />} type="text" onClick={handleEmHire} />
                  </Tooltip>
                </Badge>
              )}
            </Space>
          }
        />
        <ErrorBoundary>
          <div className="employee__content-cards">
            <Title id="basicinfo">BASIC INFORMATION</Title>
            <EmployeeBasic employeeId={id} {...eventProps} />
            {canViewPersonalTab && employee && (
              <>
                <Title id="personal">PERSONAL</Title>
                <EmployeePersonal employee={employee} {...eventProps} />
                <EmIdentities employeeId={id} id={employee.emIdentityId} />
                <EmAddresses employeeId={id} id={employee.emAddressId} />
              </>
            )}
            {canViewEmploymentTab && employee && (
              <>
                <Title id="employment">EMPLOYMENT</Title>
                <EmployeeHire employee={employee} {...eventProps} />
                <EmCareer employee={employee} />
                <EmployeeProbation employee={employee} {...eventProps} />
                <EmployeeRetirement employee={employee} {...eventProps} />
              </>
            )}
            {canViewCalendarTab && employee && (
              <>
                <Title id="calendar">CALENDAR</Title>
                <EmCalendars employeeId={id} id={employee.emCalendarId} />
              </>
            )}
            {canViewSalaryTab && employee && (
              <>
                <Title id="salary">SALARY</Title>
                <EmSalaries employeeId={id} id={employee.emSalaryId} />
                {activeTenant?.featureFlags?.includes(FeatureFlag.Payroll) && (
                  <>
                    <EmPayGroups employeeId={id} id={employee.emPayGroupId} />
                    <PayRecurrings employeeId={id} />
                  </>
                )}
              </>
            )}
            {canViewPaymentTab && employee && (
              <>
                <Title id="payment">PAYMENT</Title>
                <EmployeePayment employee={employee} {...eventProps} />
              </>
            )}
            {canViewStatutoryTab && employee && (
              <>
                <Title id="statutory">STATUTORY</Title>
                <EmployeeStatutory employee={employee} {...eventProps} />
                <EmStatutoryExempt employeeId={id} />
              </>
            )}
            {canViewLeaveTab && employee && activeTenant?.featureFlags?.includes(FeatureFlag.Leave) && (
              <>
                <Title id="leave">LEAVE</Title>
                <EmLeaveGroups employeeId={id} id={employee.emLeaveGroupId} />
                <EmLeaveBuddies employeeId={id} {...eventProps} />
              </>
            )}
            {canViewAttendanceTab && employee && activeTenant?.featureFlags?.includes(FeatureFlag.Attendance) && (
              <>
                <Title id="attendance">ATTENDANCE</Title>
                <EmployeeAttendance employee={employee} {...eventProps} />
                <EmLocations employeeId={id} id={employee.emLocationId} />
                <EmAttendanceGroups employeeId={id} id={employee.emAttendanceGroupId} />
                <EmShiftRoles employeeId={id} id={employee.emShiftRoleId} />
              </>
            )}
            {canViewFamilyTab && employee && (
              <>
                <Title id="family">FAMILY</Title>
                <EmFamilies employeeId={id} />
              </>
            )}
            {canViewEmergencyTab && employee && (
              <>
                <Title id="emergency">EMERGENCY CONTACTS</Title>
                <EmployeeEmergency employee={employee} {...eventProps} />
              </>
            )}
            {canViewEducationTab && employee && (
              <>
                <Title id="education">EDUCATION</Title>
                <EmEducations employeeId={id} />
              </>
            )}
            {canViewDocumentTab && employee && (
              <>
                <Title id="document">DOCUMENTS</Title>
                <EmDocuments employeeId={id} />
              </>
            )}
            <div className="employee__audit">
              <Audit {...pick(employee!, 'createdBy', 'createdDate', 'updatedBy', 'updatedDate')} />
            </div>
          </div>
          <SelectEmHireDrawer {...emHireDrawerState} id={id} onClose={handleEmHireDrawerClose} />
        </ErrorBoundary>
      </Content>
      {employee && (
        <UploadPhotoModal
          title="Change profile photo"
          src={employee.photoId ? `${baseUrl}/file/${employee.photoId}` : undefined}
          // src={`/employee/employee/${id}/avatar/250?photo_id=${employee.photoId}`}
          open={uploadModal}
          uploading={uploading}
          onSave={handleUploadChange}
          onClose={handleCloseUploadModal}
        />
      )}
    </Layout>
  )
}

interface TitleProps extends HTMLAttributes<HTMLDivElement> {}

const Title: FC<TitleProps> = ({ id, children }) => <section id={id}>{children}</section>
