import React, { ChangeEvent, CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import debounce from 'lodash/debounce'
import pick from 'lodash/pick'
import { EditOutlined } from '@ant-design/icons'
import {
  AmountDisplay,
  Col,
  CurrencyKeyValues,
  InfoTooltip,
  Row,
  SalaryInput,
  SubHeader,
  UploadFileSelectorAuth
} from '~/components'
import {
  Button,
  Checkbox,
  CheckboxChangeEvent,
  Form,
  Input,
  SecondaryLink,
  SecondaryText,
  Skeleton,
  Space,
  Text,
  UploadFile
} from '~/core-components'
import { usePayRun } from '~/features/payroll'
import { apiGetExchangeRate } from '~/features/claim'
import { ClaApprovalStatus, ClaAttachmentFileTypeAllowed, ClaCurrencyCode, ClaTaxableType } from '~/constants'
import { Errors } from '~/types/store'
import { useClaimRecordApprovalHistories, useClaimType, useCurrentTaxRate } from '../../../hooks'
import { getInclusiveTaxLabel, getTaxTooltip } from '../../../util'
import { ClaimRecordState, IClaimApply } from '../../../types'
import { ClaimCurrentApprover } from '../../ClaimCurrentApprover/ClaimCurrentApprover'
import { ClaimRecordApprovalHistories } from '../../ClaimRecordApprovalHistories/ClaimRecordApprovalHistories'
import { DEFAULT_EDIT_TAX_AMOUNT_MODAL_STATE, EditTaxAmountModal, EditTaxAmountModalState } from './EditTaxAmountModal'

interface ClaimRecordSingleFormProps {
  data: IClaimApply
  original?: ClaimRecordState
  loading?: boolean
  readOnly?: boolean
  onChange: (changes: Partial<IClaimApply>) => void
  onComputeClaimAmount: (
    expenseDate: string,
    expenseAmount: number,
    expenseCurrencyCode: string,
    exchangeRate: number,
    exchangeUnit: number,
    originalClaimAmount: number
  ) => Promise<{ claimAmount: number; claimAmountTax: number }>
  claimAmountLoading?: boolean
  errors?: Errors
}

const currencyStyle: CSSProperties = { width: 80 }
const taxStyle: CSSProperties = { fontWeight: 'normal', fontStyle: 'italic' }
const editTaxIconStyle: CSSProperties = { marginLeft: 8 }
const editedTaxIndicatorStyle: CSSProperties = { position: 'absolute', top: 0 }
const DEBOUNCE_TIMEOUT = 800

export const ClaimRecordSingleForm: FC<ClaimRecordSingleFormProps> = ({
  data,
  original,
  loading,
  readOnly,
  onChange,
  onComputeClaimAmount,
  claimAmountLoading,
  errors
}) => {
  const [claimType] = useClaimType(data.claimTypeId)
  const claimTypeCurrencyCode = claimType?.currencyCode || ClaCurrencyCode.sgd
  const [claimRecordApprovalHistories] = useClaimRecordApprovalHistories(original?.id)

  const isNew = !original?.id
  const isDraft = original?.submittedBy == null
  const claimAmountRef = React.useRef(0)

  const [taxRate] = useCurrentTaxRate(data.expenseCurrencyCode, data.expenseDate)

  const sourceCurrency = data.expenseCurrencyCode
  const targetCurrency = claimType?.currencyCode
  const exchangeDate = data.expenseDate
  const [exchangeRateLoading, setExchangeRateLoading] = useState(false)

  const [payRun] = usePayRun(original?.payRunId || '')
  const payRunDesc = payRun?.description || ''

  const originalClaimAmount = isDraft ? 0 : original?.claimAmount || 0

  const [editTaxAmountModalState, setEditTaxAmountModalState] = useState<EditTaxAmountModalState>(
    DEFAULT_EDIT_TAX_AMOUNT_MODAL_STATE
  )

  const fetchCurrencyExchange = useCallback(
    async (overrideSource?: string) => {
      const _sourceCurrency = overrideSource || sourceCurrency
      if (!_sourceCurrency || !targetCurrency || !exchangeDate) {
        return
      }

      if (_sourceCurrency === targetCurrency) {
        onChange({ exchangeRate: 1, exchangeUnit: 1 })
        return
      }

      try {
        setExchangeRateLoading(true)
        const { result } = await apiGetExchangeRate(_sourceCurrency, targetCurrency, exchangeDate)
        if (result) {
          const { rate, unit } = result
          onChange({ exchangeRate: rate, exchangeUnit: unit })
        }
      } finally {
        setExchangeRateLoading(false)
      }
    },
    [sourceCurrency, targetCurrency, exchangeDate, onChange]
  )

  const handleUpdateExchangeRate = useCallback(() => {
    fetchCurrencyExchange()
  }, [fetchCurrencyExchange])

  const handleOpenEditTaxAmountModal = useCallback(() => {
    setEditTaxAmountModalState({
      visible: true,
      currencyCode: taxRate.currencyCode,
      amount: data.claimAmount,
      taxAmount: data.claimAmountTax,
      isUserOverride: data.isClaimAmountTaxOverride
    })
  }, [taxRate, data])

  const handleCloseEditTaxAmountModal = useCallback(() => {
    setEditTaxAmountModalState(DEFAULT_EDIT_TAX_AMOUNT_MODAL_STATE)
  }, [])

  const debounceGetClaimAmount = React.useMemo(() => {
    const getClaimAmount = async (
      expenseDate: string,
      expenseAmount: number,
      expenseCurrencyCode: string,
      exchangeRate: number,
      exchangeUnit: number,
      originalClaimAmount: number,
      isClaimAmountTaxOverride: boolean,
      isTaxable: boolean
    ) => {
      claimAmountRef.current += 1
      const fetchId = claimAmountRef.current

      const computed = await onComputeClaimAmount(
        expenseDate,
        expenseAmount,
        expenseCurrencyCode,
        exchangeRate,
        exchangeUnit,
        originalClaimAmount
      )
      if (fetchId === claimAmountRef.current) {
        if (computed.claimAmount === 0) {
          onChange({ claimAmount: 0, claimAmountTax: 0, isClaimAmountTaxOverride: false })
        } else {
          if (isClaimAmountTaxOverride) {
            onChange({ claimAmount: computed.claimAmount })
          } else {
            onChange({ claimAmount: computed.claimAmount, claimAmountTax: isTaxable ? computed.claimAmountTax : 0 })
          }
        }
      }
    }

    return debounce(getClaimAmount, DEBOUNCE_TIMEOUT)
  }, [onComputeClaimAmount, onChange])

  useEffect(() => {
    if (!readOnly) {
      debounceGetClaimAmount(
        data.expenseDate,
        data.expenseAmount,
        data.expenseCurrencyCode,
        data.exchangeRate,
        data.exchangeUnit,
        originalClaimAmount,
        data.isClaimAmountTaxOverride,
        data.isTaxable
      )
    }
  }, [
    readOnly,
    debounceGetClaimAmount,
    data.expenseDate,
    data.expenseAmount,
    data.expenseCurrencyCode,
    data.exchangeRate,
    data.exchangeUnit,
    originalClaimAmount,
    data.isClaimAmountTaxOverride,
    data.isTaxable
  ])

  useEffect(() => {
    if (claimTypeCurrencyCode && !data.expenseCurrencyCode) {
      onChange({ expenseCurrencyCode: claimTypeCurrencyCode })
    }
  }, [claimTypeCurrencyCode, data.expenseCurrencyCode, onChange])

  const handleFileChange = useCallback(
    (files?: UploadFile[]) => {
      if (files && files.length > 0) {
        const updated = { ...data, attachments: files }
        typeof onChange === 'function' && onChange(updated)
      }
    },
    [data, onChange]
  )

  const handleFileRemove = useCallback(
    (file?: UploadFile) => {
      let deletedFileIds: string[] | undefined = data.deletedAttachmentIds || []
      if (file) {
        const isNewFile = file instanceof File
        if (!isNewFile) deletedFileIds?.push(file.uid)

        const listAttachment = data.attachments || []
        const updated = {
          ...data,
          attachments: listAttachment.filter(x => x.uid !== file.uid),
          ...(isNewFile ? {} : { deletedAttachmentIds: deletedFileIds })
        }
        typeof onChange === 'function' && onChange(updated)
      }
    },
    [data, onChange]
  )

  const includeTaxLabel = getInclusiveTaxLabel(taxRate?.currencyCode, 'Inclusive of')
  const includeTaxTooltip = getTaxTooltip(taxRate?.currencyCode)

  if (!data.employeeId || !data.claimTypeId) return null
  if (loading) return <Skeleton active />

  return (
    <>
      <Row>
        <Col span={24}>
          <Form.Item
            label={readOnly ? 'Uploaded receipt(s)' : 'Upload receipt(s)'}
            validateStatus={errors?.attachments ? 'error' : ''}
            help={errors?.attachments}
          >
            {readOnly && data.attachments?.length === 0 ? (
              '-'
            ) : (
              <UploadFileSelectorAuth
                accept={ClaAttachmentFileTypeAllowed.toString()}
                fileList={data.attachments}
                listType="picture-card"
                multiple
                disabled={readOnly}
                onChange={handleFileChange}
                onRemove={handleFileRemove}
              >
                <div>
                  <i className="fal fa-plus" />
                  <Text size="small" block>
                    Upload image or PDF
                  </Text>
                </div>
              </UploadFileSelectorAuth>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[15, 0]}>
        <Col span={12}>
          <Form.Item label="Receipt no." validateStatus={errors?.receiptNo ? 'error' : ''} help={errors?.receiptNo}>
            {readOnly ? (
              data.receiptNo || '-'
            ) : (
              <Input
                value={data.receiptNo}
                onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ receiptNo: event.target.value })}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Expense date"
            validateStatus={errors?.expenseDate ? 'error' : ''}
            help={errors?.expenseDate}
          >
            {readOnly ? (
              data.expenseDate ? (
                moment(data.expenseDate).format('DD MMM YYYY')
              ) : (
                '-'
              )
            ) : (
              <Input.Date
                allowClear={false}
                value={data.expenseDate ? moment(data.expenseDate) : undefined}
                onChange={(value: moment.Moment | null) => onChange({ expenseDate: value?.format('YYYY-MM-DD') })}
                disabledDate={current => current && current > moment().endOf('day')}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[15, 0]}>
        <Col span={12}>
          <Form.Item
            label={
              readOnly ? (
                <Space>
                  <span>Expense amount</span>
                  {data.isTaxable && <i>({includeTaxLabel})</i>}
                </Space>
              ) : (
                <>
                  Expense amount
                  {data.isTaxable && <InfoTooltip title={includeTaxTooltip} />}
                </>
              )
            }
            validateStatus={errors?.expenseAmount ? 'error' : ''}
            help={errors?.expenseAmount}
          >
            {readOnly ? (
              <AmountDisplay symbol={data.expenseCurrencyCode} value={data.expenseAmount} />
            ) : (
              <Space direction="vertical">
                <Space>
                  <CurrencyKeyValues
                    labelProp={kv => kv.key.toUpperCase()}
                    optionLabelProp="label"
                    dropdownMatchSelectWidth={false}
                    allowClear={false}
                    style={currencyStyle}
                    value={data.expenseCurrencyCode}
                    onChange={(expenseCurrencyCode: string) => {
                      onChange({ expenseCurrencyCode, claimAmountTax: 0, exchangeRate: 0, exchangeUnit: 1 })
                      fetchCurrencyExchange(expenseCurrencyCode)
                    }}
                  />
                  <SalaryInput
                    min={0}
                    value={data.expenseAmount}
                    onChange={(value: number | null) => onChange({ expenseAmount: value as number })}
                  />
                </Space>
                {taxRate?.currencyCode === data.expenseCurrencyCode &&
                  claimType?.taxableType === ClaTaxableType.Yes && (
                    <Checkbox
                      checked={data.isTaxable}
                      readOnly={readOnly || claimAmountLoading}
                      onChange={(event: CheckboxChangeEvent) => onChange({ isTaxable: event.target.checked })}
                    >
                      {includeTaxLabel}
                    </Checkbox>
                  )}
              </Space>
            )}
          </Form.Item>
        </Col>
        <Col
          span={12}
          hidden={
            !claimType?.currencyCode ||
            !data.expenseCurrencyCode ||
            claimType?.currencyCode === data.expenseCurrencyCode
          }
        >
          <Form.Item
            label="Exchange rate"
            validateStatus={errors?.exchangeRate ? 'error' : ''}
            help={errors?.exchangeRate}
          >
            {readOnly ? (
              <Space>
                {data.exchangeRate}
                {data.exchangeUnit > 1 && <SecondaryText size="small">/ {data.exchangeUnit}</SecondaryText>}
              </Space>
            ) : (
              <Space style={{ display: 'block' }}>
                <Input.Group compact>
                  <Input.Number
                    min={0}
                    value={data.exchangeRate}
                    formatter={value => `${value}`.replace(/(?<!\.\d*)(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}
                    style={{ width: 110 }}
                    onChange={(value: number | null) => onChange({ exchangeRate: value as number })}
                  />
                  <Button
                    type="default"
                    icon={<i className="fal fa-refresh" />}
                    onClick={handleUpdateExchangeRate}
                    loading={exchangeRateLoading}
                  />
                </Input.Group>
                {data.exchangeUnit > 1 && <SecondaryText size="small">/ {data.exchangeUnit}</SecondaryText>}
              </Space>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Vendor / Provider"
            validateStatus={errors?.providerName ? 'error' : ''}
            help={errors?.providerName}
          >
            {readOnly ? (
              data.providerName || '-'
            ) : (
              <Input
                value={data.providerName}
                onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ providerName: event.target.value })}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Notes" validateStatus={errors?.notes ? 'error' : ''} help={errors?.notes}>
            {readOnly ? (
              data.notes || '-'
            ) : (
              <Input.TextArea
                rows={2}
                value={data.notes}
                onChange={(value?: ChangeEvent<HTMLTextAreaElement>) => onChange({ notes: value?.target.value })}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      {payRunDesc && (
        <Row>
          <Col span={24}>
            <Form.Item label="Payment">{payRunDesc}</Form.Item>
          </Col>
        </Row>
      )}
      {[ClaApprovalStatus.pending, ClaApprovalStatus.pendingCancel].some(s => original?.recordStatus === s) && (
        <ClaimCurrentApprover approvers={original?.currentApprovers || []} hidden={!readOnly || isNew} />
      )}
      <ClaimRecordApprovalHistories
        histories={claimRecordApprovalHistories}
        status={original?.recordStatus || ''}
        hidden={!readOnly || isNew}
        recordType={original?.recordType}
        {...pick(original!, 'creatorName', 'createdDate', 'submitterName', 'submittedDate')}
      />
      <Row className="claim-record-form__claim-amount">
        <Col span={24}>
          <SubHeader type="primary">
            <Row>
              <Col flex="auto">Claim amount</Col>
              <Col flex="none">
                <AmountDisplay symbol={claimType?.currencyCode} value={data.claimAmount} loading={claimAmountLoading} />
              </Col>
              <Col flex="20px" />
            </Row>
            {taxRate?.currencyCode === data.expenseCurrencyCode && !claimAmountLoading && data.isTaxable && (
              <Row>
                <Col flex="auto" />
                <Col flex="none">
                  <SecondaryText size="small" style={taxStyle}>
                    {includeTaxLabel} <AmountDisplay symbol={claimType?.currencyCode} value={data.claimAmountTax} />
                    {data.isClaimAmountTaxOverride && <span style={editedTaxIndicatorStyle}>*</span>}
                  </SecondaryText>
                </Col>
                <Col flex="20px">
                  {!readOnly && (
                    <SecondaryLink style={editTaxIconStyle}>
                      <EditOutlined onClick={handleOpenEditTaxAmountModal} />
                    </SecondaryLink>
                  )}
                </Col>
              </Row>
            )}
          </SubHeader>
          <EditTaxAmountModal
            {...editTaxAmountModalState}
            onApply={(claimAmountTax, isReset) => onChange({ claimAmountTax, isClaimAmountTaxOverride: !isReset })}
            onClose={handleCloseEditTaxAmountModal}
          />
        </Col>
      </Row>
    </>
  )
}
