import { Operation } from 'fast-json-patch'
import { request, getBaseUrl, timeout } from '~/utils'
import { DateRange, Pagination } from '~/types/common'
import { TimeLogState, TimeLogViewState, ISaveTimeLog } from '../types'

const baseUrl = getBaseUrl('/attendance/timelog')

export const apiGetTimeLogsView = (viewId: string, params: Pagination & DateRange) =>
  request<TimeLogViewState>('get', `${baseUrl}/view/${viewId}`, params)

export const apiGetTimeLogsExcel = (viewId: string, params: DateRange) =>
  request('get', `${baseUrl}/view/${viewId}/excel`, params, { responseType: 'blob', timeout })

export const apiGetTimeLogsByDaily = (dailyRecordId: string) =>
  request<TimeLogState[]>('get', `${baseUrl}/daily/${dailyRecordId}`)

export const apiAddTimeLog = (timeLog: ISaveTimeLog) => request<{ id: string }>('post', baseUrl, timeLog)

export const apiUpdateTimeLog = (id: string, timeLog: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}`, timeLog)

export const apiDeleteTimeLog = (id: string) => request<void>('delete', `${baseUrl}/${id}`)
