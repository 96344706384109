import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { EmployeeState, EmPublicPersonState, EmPublicProfileState, EmPublicState, EmHireState } from './employee.state'
import {
  EmIdentityState,
  EmAddressState,
  EmFamilyState,
  EmEducationState,
  EmDocumentState,
  EmCalendarState,
  EmSalaryState,
  EmPayGroupState,
  EmLeaveGroupState,
  EmLocationState,
  EmAttendanceGroupState,
  EmShiftRoleState,
  EmCompanyState,
  EmDepartmentState,
  EmJobState,
  EmManagerState,
  EmTeamState,
  EmOfficeState,
  EmCostCentreState,
  EmEmploymentTypeState,
  EmStatutoryExemptState,
  EmPreferenceState,
  EmDivisionState,
  EmCategoryState,
  EmGrpState,
  EmOtClassState,
  EmSectionState,
  EmManagerSecondaryState,
  EmLeaveBuddyState
} from './em-details.state'
import { EmDirectoryViewState } from './em-directory.state'
import { EmSelectionViewState } from './em-selection.state'
import { EmployeeImportState } from './employee-import.state'
import { KeyValue } from '~/types/common'
import { BarChartState, ChartSummaryState, PieChartState } from './em-dashboard.state'
import { EmRequestState } from './em-request.state'
import { EmIdentityRequestState } from './em-identity-request.state'
import { EmAddressRequestState } from './em-address-request.state'
import { EmEducationRequestState } from './em-education-request.state'
import { EmFamilyRequestState } from './em-family-request.state'

export const employeePersistConfig = {
  key: 'employee',
  whitelist: [
    'emKeyvalues',
    'emPublic',
    'emPublicPersons',
    'emPublicProfiles',
    'employees',
    'employeesRefetch',
    'emHires',
    'emIdentities',
    'emAddresses',
    'emFamilies',
    'emEducations',
    'emDocuments',
    'emCalendars',
    'emSalaries',
    'emPayGroups',
    'emLeaveGroups',
    'emCompanies',
    'emDepartments',
    'emDivisions',
    'emSections',
    'emGrps',
    'emCategories',
    'emTeams',
    'emJobs',
    'emManagers',
    'emManagerSecondaries',
    'emOffices',
    'emCostCentres',
    'emEmploymentTypes',
    'emOtClasses',
    'emStatutoryExempts',
    'emDirectoryView',
    'emDirectoryViewRefetch',
    'emSelection',
    'emSelected',
    'permittedEmployeePay',
    'permittedEmployeeLve',
    'permittedEmployeeAtt',
    'emFieldValues',
    'emPreference',
    'emRequests',
    'emIdentityRequests',
    'emAddressRequests',
    'emEducationRequests',
    'emFamilyRequests',
    'oedApexState',
    'oedApexStateTimestamp',
    'oedSubmissionId'
  ],
  storage
}

export interface EmployeeRootState {
  emKeyValues: EntityState<KeyValue>
  emKeyValuesLoading: boolean
  emPublic: EntityState<EmPublicState>
  emPublicLoading: boolean
  emPublicPersons: EntityState<EmPublicPersonState>
  emPublicPersonsLoading: boolean
  emPublicProfiles: EntityState<EmPublicProfileState>
  emPublicProfilesLoading: boolean
  employees: EntityState<EmployeeState>
  employeesLoading: boolean
  employeesRefetch: { [employeeId: string]: number }
  emHires: { [employeeId: string]: EntityState<EmHireState> }
  emHiresLoading: { [employeeId: string]: boolean }
  emIdentities: { [employeeId: string]: EntityState<EmIdentityState> }
  emIdentitiesLoading: { [employeeId: string]: boolean }
  emAddresses: { [employeeId: string]: EntityState<EmAddressState> }
  emAddressesLoading: { [employeeId: string]: boolean }
  emFamilies: { [employeeId: string]: EntityState<EmFamilyState> }
  emFamiliesLoading: { [employeeId: string]: boolean }
  emEducations: { [employeeId: string]: EntityState<EmEducationState> }
  emEducationsLoading: { [employeeId: string]: boolean }
  emDocuments: { [employeeId: string]: EntityState<EmDocumentState> }
  emDocumentsLoading: { [employeeId: string]: boolean }
  emCalendars: { [employeeId: string]: EntityState<EmCalendarState> }
  emCalendarsLoading: { [employeeId: string]: boolean }
  emSalaries: { [employeeId: string]: EntityState<EmSalaryState> }
  emSalariesLoading: { [employeeId: string]: boolean }
  emPayGroups: { [employeeId: string]: EntityState<EmPayGroupState> }
  emPayGroupsLoading: { [employeeId: string]: boolean }
  emLeaveGroups: { [employeeId: string]: EntityState<EmLeaveGroupState> }
  emLeaveGroupsLoading: { [employeeId: string]: boolean }
  emLeaveBuddies: { [employeeId: string]: EntityState<EmLeaveBuddyState> }
  emLeaveBuddiesLoading: { [employeeId: string]: boolean }
  emLocations: { [employeeId: string]: EntityState<EmLocationState> }
  emLocationsLoading: { [employeeId: string]: boolean }
  emAttendanceGroups: { [employeeId: string]: EntityState<EmAttendanceGroupState> }
  emAttendanceGroupsLoading: { [employeeId: string]: boolean }
  emShiftRoles: { [employeeId: string]: EntityState<EmShiftRoleState> }
  emShiftRolesLoading: { [employeeId: string]: boolean }
  emCompanies: { [employeeId: string]: EntityState<EmCompanyState> }
  emCompaniesLoading: { [employeeId: string]: boolean }
  emDepartments: { [employeeId: string]: EntityState<EmDepartmentState> }
  emDepartmentsLoading: { [employeeId: string]: boolean }
  emDivisions: { [employeeId: string]: EntityState<EmDivisionState> }
  emDivisionsLoading: { [employeeId: string]: boolean }
  emSections: { [employeeId: string]: EntityState<EmSectionState> }
  emSectionsLoading: { [employeeId: string]: boolean }
  emGrps: { [employeeId: string]: EntityState<EmGrpState> }
  emGrpsLoading: { [employeeId: string]: boolean }
  emCategories: { [employeeId: string]: EntityState<EmCategoryState> }
  emCategoriesLoading: { [employeeId: string]: boolean }
  emTeams: { [employeeId: string]: EntityState<EmTeamState> }
  emTeamsLoading: { [employeeId: string]: boolean }
  emJobs: { [employeeId: string]: EntityState<EmJobState> }
  emJobsLoading: { [employeeId: string]: boolean }
  emManagers: { [employeeId: string]: EntityState<EmManagerState> }
  emManagersLoading: { [employeeId: string]: boolean }
  emManagerSecondaries: { [employeeId: string]: EntityState<EmManagerSecondaryState> }
  emManagerSecondariesLoading: { [employeeId: string]: boolean }
  emOffices: { [employeeId: string]: EntityState<EmOfficeState> }
  emOfficesLoading: { [employeeId: string]: boolean }
  emCostCentres: { [employeeId: string]: EntityState<EmCostCentreState> }
  emCostCentresLoading: { [employeeId: string]: boolean }
  emEmploymentTypes: { [employeeId: string]: EntityState<EmEmploymentTypeState> }
  emEmploymentTypesLoading: { [employeeId: string]: boolean }
  emOtClasses: { [employeeId: string]: EntityState<EmOtClassState> }
  emOtClassesLoading: { [employeeId: string]: boolean }
  emStatutoryExempts: { [employeeId: string]: EntityState<EmStatutoryExemptState> }
  emStatutoryExemptsLoading: { [employeeId: string]: boolean }
  emDirectoryView: EntityState<EmDirectoryViewState>
  emDirectoryViewLoading: boolean
  emDirectoryViewExpanded: boolean
  emDirectoryViewRefetch: number
  emSelection: EntityState<EmSelectionViewState>
  emSelectionLoading: boolean
  refreshEmployeeLoading: boolean
  emFieldValues: { [field: string]: string[] }
  emFieldValuesLoading: { [field: string]: boolean }
  employeeImport?: EmployeeImportState
  emPreference: EntityState<EmPreferenceState>
  emPreferenceLoading: boolean
  emRequests: { [employeeId: string]: EntityState<EmRequestState> }
  emRequestsLoading: { [employeeId: string]: boolean }
  emIdentityRequests: { [employeeId: string]: EntityState<EmIdentityRequestState> }
  emIdentityRequestsLoading: { [employeeId: string]: boolean }
  emAddressRequests: { [employeeId: string]: EntityState<EmAddressRequestState> }
  emAddressRequestsLoading: { [employeeId: string]: boolean }
  emEducationRequests: { [employeeId: string]: EntityState<EmEducationRequestState> }
  emEducationRequestsLoading: { [employeeId: string]: boolean }
  emFamilyRequests: { [employeeId: string]: EntityState<EmFamilyRequestState> }
  emFamilyRequestsLoading: { [employeeId: string]: boolean }

  // Permission
  permittedEmployeePay: string[]
  permittedEmployeeLve: string[]
  permittedEmployeeAtt: string[]

  // EmDashboard
  chartSummaries: { [chartId: string]: ChartSummaryState }
  chartSummariesLoading: { [chartId: string]: boolean }
  headcountGroupBy: { [key: string]: PieChartState }
  headcountGroupByLoading: { [key: string]: boolean }
  chartServiceLen?: BarChartState
  chartServiceLenLoading: boolean

  // Apex for OED
  oedApexState: string
  oedApexStateTimestamp: number
  oedSubmissionId: string
}
