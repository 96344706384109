import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import { LoadingOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { AlignType, ColumnsType, Link, Space, Spin, Table, Tag, Tooltip } from '~/core-components'
import { Col, Row } from '~/components'
import { Screen, useViewSchema } from '~/features/selection'
import { StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { formatYearMonth, formatMonth, formatMoney } from '~/utils'
import { selectDailyRecordsByEmployeeView } from '../../../selectors'
import { fetchDailyRecordsByEmployeeView } from '../../../actions'
import { DailyRecordRowState } from '../../../types'
import { DailyRecordDrawer } from '../../DailyRecord/DailyRecordDrawer'
import './DailyRecordsByEmployee.less'

interface DailyRecordsByEmployeeProps {
  viewId: string
  startDate: string | null
  endDate: string | null
  employeeId: string
}

interface DrawerState {
  visible: boolean
  employeeId: string
  clockDate: string
  shiftId: string
}

const nameColWidth = 250
const dateTimeColWidth = 250
const defaultColWidth = 150

const SCREEN_CODE: Screen = 'daily_summary'
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false, employeeId: '', clockDate: '', shiftId: '' }

export const DailyRecordsByEmployee: FC<DailyRecordsByEmployeeProps> = ({ viewId, employeeId, startDate, endDate }) => {
  const selection = useSelector((state: StoreState) => state.selection.sysSelectionFields[SCREEN_CODE])
  const [schema] = useViewSchema(SCREEN_CODE, viewId)

  const data = useSelector(selectDailyRecordsByEmployeeView)(employeeId || '', viewId || '')
  const refetch = useSelector(
    (state: StoreState) => state.attendance.dailyRecordsByEmployeeViewRefetch[employeeId || '']
  )

  const viewLoading = useSelector((state: StoreState) => state.selection.viewLoading[SCREEN_CODE]?.[viewId || ''])
  const dataLoading = useSelector(
    (state: StoreState) => state.attendance.dailyRecordsByEmployeeViewLoading[employeeId || '']
  )
  const loading = dataLoading || viewLoading
  const processing = useSelector((state: StoreState) => state.attendance.dailyProcessing)

  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)

  useEffect(() => {
    if (viewId && employeeId && !processing) {
      dispatch(fetchDailyRecordsByEmployeeView(viewId, employeeId, startDate, endDate))
    }
  }, [viewId, employeeId, startDate, endDate, processing, refetch])

  const handleOpenDailyRecord = useCallback(
    (clockDate: string, shiftId: string) => {
      setDrawerState({ visible: true, employeeId, clockDate, shiftId })
    },
    [employeeId]
  )

  const handleCloseDrawer = useCallback(() => {
    console.log('handleCloseDrawer', employeeId)
    setDrawerState({ ...DEFAULT_DRAWER_STATE, employeeId })
  }, [employeeId])

  const tableWidth = useMemo(() => {
    return (
      (schema?.selection
        .filter(f => selection?.ids.includes(f.selectionFieldId))
        .reduce((sum, f) => (sum += selection?.entities[f.selectionFieldId]?.width || defaultColWidth), 0) || 0) +
      nameColWidth +
      dateTimeColWidth
    )
  }, [schema, selection])

  const columns = useMemo(() => {
    let columns: ColumnsType<DailyRecordRowState> = []

    columns.push({
      title: 'Date time',
      key: 'clockDate',
      dataIndex: 'clockDate',
      fixed: 'left',
      width: dateTimeColWidth,
      render: (value: string, record) => {
        let startTime: moment.Moment | undefined = undefined,
          endTime: moment.Moment | undefined = undefined,
          isPrevDayInTime = false,
          isNextDayInTime = false,
          isPrevDayOutTime = false,
          isNextDayOutTime = false

        if (record.startTime) {
          startTime = moment(record.startTime)
          isPrevDayInTime = startTime.isBefore(record.clockDate, 'date')
          isNextDayInTime = startTime.isAfter(record.clockDate, 'date')
        }

        if (record.endTime) {
          endTime = moment(record.endTime)
          isPrevDayOutTime = endTime.isBefore(record.clockDate, 'date')
          isNextDayOutTime = endTime.isAfter(record.clockDate, 'date')
        }

        return (
          <Link onClick={() => handleOpenDailyRecord(moment(record.clockDate).format('YYYY-MM-DD'), record.shiftId)}>
            <Row>
              <Col flex="130px">{value ? moment(value).format('DD MMM YYYY (ddd)') : ''}</Col>
              <Col flex="1">
                <Space>
                  {isPrevDayInTime && (
                    <Tooltip title={startTime?.format('DD MMM YYYY')}>
                      <i className="fal fa-calendar-minus" />
                    </Tooltip>
                  )}
                  {isNextDayInTime && (
                    <Tooltip title={startTime?.format('DD MMM YYYY')}>
                      <i className="fal fa-calendar-plus" />
                    </Tooltip>
                  )}
                  {startTime?.format('HH:mm')}
                  <> - </>
                  {isPrevDayOutTime && (
                    <Tooltip title={endTime?.format('DD MMM YYYY')}>
                      <i className="fal fa-calendar-minus" />
                    </Tooltip>
                  )}
                  {isNextDayOutTime && (
                    <Tooltip title={endTime?.format('DD MMM YYYY')}>
                      <i className="fal fa-calendar-plus" />
                    </Tooltip>
                  )}
                  {endTime?.format('HH:mm')}
                </Space>
              </Col>
            </Row>
          </Link>
        )
      }
    })

    if (schema) {
      // Configurable employee columns
      columns.push(
        ...schema?.selection
          .filter(f => selection?.ids.includes(f.selectionFieldId))
          .map(f => {
            const field = selection?.entities[f.selectionFieldId]
            const align = field?.format === 'money' ? 'right' : ('left' as AlignType)

            if (field?.fieldName === 'dailyLeaveTypes') {
              return {
                title: field.description,
                key: field.fieldName,
                dataIndex: field.fieldName,
                width: field.width || defaultColWidth,
                align,
                render: (value: string) => {
                  const obj = JSON.parse(value || '{}') as {
                    full_leave: string
                    h1_leave: string
                    h2_leave: string
                    hour_leave: string
                  }
                  return (
                    <Space>
                      {obj.full_leave && <Tag>{obj.full_leave}</Tag>}
                      {obj.h1_leave && <Tag>{obj.h1_leave}</Tag>}
                      {obj.h2_leave && <Tag>{obj.h2_leave}</Tag>}
                      {obj.hour_leave && <Tag>{obj.hour_leave}</Tag>}
                    </Space>
                  )
                }
              }
            } else {
              return {
                title: field?.description,
                key: field?.fieldName,
                dataIndex: field?.fieldName,
                width: field?.width || defaultColWidth,
                align,
                render: (value: string | number | boolean) => {
                  var display = value
                  if (field?.format === 'date' && value) {
                    display = moment(value as string).format('DD MMM YYYY')
                  }
                  if (field?.format === 'yearmonth' && value) {
                    display = formatYearMonth(value as string)
                  }
                  if (field?.format === 'month' && value) {
                    display = formatMonth(value as number)
                  }
                  if (field?.format === 'money' && value) {
                    display = formatMoney(value as number)
                  }
                  if (field?.format === 'yes_no') {
                    display = (value as boolean) ? 'Yes' : 'No'
                  }

                  return display
                }
              }
            }
          })
      )
    }

    return columns
  }, [schema, selection, handleOpenDailyRecord])

  return (
    <>
      {loading ? (
        <Spin indicator={<LoadingOutlined spin />} />
      ) : (
        <Table
          rowKey="id"
          className="daily-records-by-employee"
          dataSource={data?.data}
          columns={columns}
          fitParent
          loading={loading}
          scroll={{ x: tableWidth }}
          pagination={false}
        />
      )}
      <DailyRecordDrawer onClose={handleCloseDrawer} {...drawerState} />
    </>
  )
}
