import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Button, Card, ColumnsType, Form, Space, Table, Tooltip } from '~/core-components'
import { DateCustomPicker, DateCustomPickerItem, Person, SearchInput } from '~/components'
import { useSysOptions } from '~/features/employee'
import {
  Screen,
  ViewCriteriaSimple,
  ViewCriteria,
  updateViewCriteria,
  useFirstView,
  useViewSchema,
  ViewSelectionButton
} from '~/features/selection'
import { usePermissionGate } from '~/features/iam'
import { LveApprovalStatus, Permission, PermissionAction } from '~/constants'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { EMP_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { DateRange } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchLeaveRecordsView } from '../../actions/fetch-leave-records-view'
import { selectLeaveRecordsView } from '../../selectors'
import { apiGetLeaveRecordExcel } from '../../api/leave-record.api'
import {
  showError,
  formatYearMonth,
  formatMonth,
  formatMoney,
  getFileTimestamp,
  formatDateRange,
  downloadWithDom,
  getBaseUrl
} from '~/utils'
import { LeaveRecordRowState } from '../../types'
import { refetchLeaveRecordsView } from '../../reducers'
import { useLeaveTypesDict } from '../../hooks'
import { MutateLeaveRecordDrawer } from './components/MutateLeaveRecordDrawer'
import { LeaveTypeName } from '../LeaveEntitlement/components/LeaveTypeName'
import { LeaveStatusTag } from '../LeaveStatusTag/LeaveStatusTag'
import './LeaveRecord.less'

interface LeaveRecordProps {}

interface DrawerState {
  visible: boolean
  employeeId?: string
  id?: string
  editing?: boolean
}

const nameColWidth = 250
const defaultColWidth = 150
const paginationStyle: CSSProperties = { marginRight: 20 }

const SCREEN_CODE: Screen = 'leave_record'
const PAGE_SIZE_OPTIONS = ['20', '50', '100']
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false, editing: false }

const TODAY_YEAR = moment().year()
const START_DATE = moment().startOf('year').format('YYYY-MM-DD')
const END_DATE = moment().endOf('year').format('YYYY-MM-DD')

const PERIOD_OPTIONS: DateCustomPickerItem[] = [
  {
    key: TODAY_YEAR.toString(),
    value: 'This year',
    startDate: START_DATE,
    endDate: END_DATE
  },
  {
    key: (TODAY_YEAR - 1).toString(),
    value: 'Last year',
    startDate: moment().startOf('year').add(-1, 'year').format('YYYY-MM-DD'),
    endDate: moment().endOf('year').add(-1, 'year').format('YYYY-MM-DD')
  }
]

const baseUrl = getBaseUrl('/filestore')
const tooltipOverlayStyle: CSSProperties = { whiteSpace: 'pre-line', maxWidth: 400 }
const approverIconStyle: CSSProperties = { fontSize: 12 }

export const LeaveRecord: FC<LeaveRecordProps> = () => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [search, setSearch] = useState<string>('')
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)

  const [leaveTypes] = useLeaveTypesDict()
  const selection = useSelector((state: StoreState) => state.selection.sysSelectionFields[SCREEN_CODE])
  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const [schema] = useViewSchema(SCREEN_CODE, viewId)
  const dataLoading = useSelector((state: StoreState) => state.leave.leaveRecordsViewLoading)
  const data = useSelector(selectLeaveRecordsView)(viewId)
  const isMountedRef = useIsMountedRef()
  const [downloading, setDownloading] = useState(false)
  const [leaveUnits] = useSysOptions('lve_unit')
  const canModify = usePermissionGate(Permission.lveRecord, PermissionAction.Modify)
  const canViewEmployee = usePermissionGate(Permission.employee)
  const leaveExpanded = useSelector((state: StoreState) => state.leave.leaveExpanded)
  const refetch = useSelector((state: StoreState) => state.leave.leaveRecordsViewRefetch)

  const [startDate, setStartDate] = useState<string | null>(START_DATE)
  const [endDate, setEndDate] = useState<string | null>(END_DATE)

  useEffect(() => {
    if (viewId)
      dispatch(
        fetchLeaveRecordsView(
          viewId,
          { offset: pageSize * (page - 1), limit: pageSize },
          search,
          startDate,
          endDate,
          null
        )
      )
  }, [viewId, page, pageSize, search, startDate, endDate, refetch])

  const handleDateRangeChange = useCallback((startDate: string | null, endDate: string | null) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }, [])

  const handleOpenLeaveRecord = useCallback(
    (leaveRecord: LeaveRecordRowState) => {
      setDrawerState({ visible: true, editing: false, id: leaveRecord.id, employeeId: leaveRecord.employeeId })
    },
    [setDrawerState]
  )

  const handleAddLeaveRecord = useCallback(() => {
    setDrawerState({ visible: true, editing: true })
  }, [setDrawerState])

  const tableWidth =
    (schema?.selection.reduce(
      (sum, f) => (sum += selection?.entities[f.selectionFieldId]?.width || defaultColWidth),
      0
    ) || 0) + nameColWidth

  let columns: ColumnsType<LeaveRecordRowState> = [
    {
      title: 'Name',
      key: 'employeeId',
      dataIndex: 'employeeId',
      fixed: 'left',
      className: 'first-col',
      width: nameColWidth,
      onCell: (record, index) => {
        const prevId = data.data[(index as number) - 1]?.employeeId
        if (prevId === record.employeeId) {
          return { rowSpan: 0 }
        } else {
          const nextId = data.data[(index as number) + 1]?.employeeId
          if (nextId === record.employeeId) {
            return { rowSpan: record.rowSpan }
          }
        }
        return {}
      },
      render: (_, record) => (
        <Person
          name={record.employeeName}
          description={!leaveExpanded && record.description}
          photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/${leaveExpanded ? 18 : 36}`}
          // photo={
          //   record.employeeId &&
          //   `/employee/employee/${record.employeeId}/avatar/${leaveExpanded ? 18 : 36}?photo_id=${record.photoId}`
          // }
          path={canViewEmployee ? EMP_ROUTES.employee.replace(':id', record.employeeId) : undefined}
          size={leaveExpanded ? 18 : 36}
        />
      )
    }
  ]

  if (schema) {
    // Configurable employee columns
    columns.push(
      ...schema?.selection.map(f => {
        var field = selection?.entities[f.selectionFieldId]
        return {
          title: field?.description,
          key: field?.fieldName,
          dataIndex: field?.fieldName,
          width: field?.width || defaultColWidth,
          onCell: (record: LeaveRecordRowState, index?: number) => {
            const prevId = data.data[(index as number) - 1]?.employeeId
            if (prevId === record.employeeId) {
              return { rowSpan: 0 }
            } else {
              const nextId = data.data[(index as number) + 1]?.employeeId
              if (nextId === record.employeeId) {
                return { rowSpan: record.rowSpan }
              }
            }
            return {}
          },
          render: (value: string | number) => {
            var display = value
            if (field?.format === 'date' && value) {
              display = moment(value).format('DD MMM YYYY')
            }
            if (field?.format === 'yearmonth' && value) {
              display = formatYearMonth(value as string)
            }
            if (field?.format === 'month' && value) {
              display = formatMonth(value as number)
            }
            if (field?.format === 'money' && value) {
              display = formatMoney(value as number)
            }
            return display
          }
        }
      })
    )
  }

  // Fixed leave entitlement columns
  columns.push(
    ...([
      {
        title: 'Leave type',
        key: 'leaveTypeId',
        dataIndex: 'leaveTypeId',
        width: 180,
        render: (value: string, record) => <LeaveTypeName id={value} path={() => handleOpenLeaveRecord(record)} />
      },
      {
        title: 'Date',
        key: 'startDate',
        dataIndex: 'startDate',
        width: 180,
        render: (value: string, record: LeaveRecordRowState) => formatDateRange(record.startDate, record.endDate)
      },
      {
        title: 'Duration',
        key: 'leaveTotal',
        dataIndex: 'leaveTotal',
        width: 100,
        align: 'right',
        render: (value: string, record: LeaveRecordRowState) =>
          `${value} ${leaveUnits[leaveTypes[record.leaveTypeId]?.unit || '']?.value?.toLowerCase() || ''}`
      },
      {
        title: 'Status',
        key: 'approvalStatuses',
        dataIndex: 'approvalStatuses',
        width: 180,
        render: (value: string[], record: LeaveRecordRowState) => (
          <Space>
            <LeaveStatusTag statuses={value} recordType={record.recordType} />
            {[LveApprovalStatus.pending, LveApprovalStatus.pendingCancel].some(s => value.includes(s)) && (
              <Tooltip
                title={`Current approver: \n ${record.currentApprovers?.join('\n')}`}
                overlayStyle={tooltipOverlayStyle}
              >
                <i className="fal fa-user" style={approverIconStyle}></i>
              </Tooltip>
            )}
          </Space>
        )
      },
      {
        title: <i className="fal fa-paperclip-vertical att-icon-header" />,
        key: 'attachmentCount',
        dataIndex: 'attachmentCount',
        width: 50,
        render: (value: number) => {
          return (
            value > 0 && (
              <>
                <i className="fal fa-paperclip-vertical" />
                <span className="att-count">{value}</span>
              </>
            )
          )
        }
      },
      {
        title: 'Notes',
        key: 'notes',
        dataIndex: 'notes',
        width: 300
      }
    ] as ColumnsType<LeaveRecordRowState>)
  )

  const handleCloseSelectionDrawer = useCallback((changed?: boolean) => {
    if (changed) dispatch(refetchLeaveRecordsView())
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchLeaveRecordsView())
      }
    },
    [viewId]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleSearch = useCallback((value: string) => {
    setPage(1)
    setSearch(value)
  }, [])

  const handleCloseDrawer = useCallback(
    (action: 'saved' | 'cancelled') => {
      if (action === 'saved' && viewId) {
        dispatch(refetchLeaveRecordsView())
      }
      setDrawerState(DEFAULT_DRAWER_STATE)
    },
    [viewId]
  )

  const handleDownloadClick = useCallback(async () => {
    if (viewId) {
      try {
        setDownloading(true)
        const employeeId: string | null = null
        const { status, result, errors, message, errorData } = await apiGetLeaveRecordExcel(viewId, {
          search,
          ...({ startDate, endDate } as DateRange),
          employeeId
        })

        if (status) {
          const fileName = `leave_records_${getFileTimestamp()}.xlsx`
          downloadWithDom(result, fileName)
        } else {
          console.error('Error while downloading', errors)
          showError(message, errorData)
        }
      } finally {
        if (isMountedRef.current) setDownloading(false)
      }
    }
  }, [viewId, search, startDate, endDate, isMountedRef])

  return (
    <div className="leave-record">
      <div className="leave-record__body">
        <div className="leave-record__action-bar">
          <Form.Item label="">
            <SearchInput onSearch={handleSearch} />
          </Form.Item>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          <Space align="start">
            <DateCustomPicker
              defaultValue={TODAY_YEAR.toString()}
              options={PERIOD_OPTIONS}
              onChange={handleDateRangeChange}
            />
            {canModify && (
              <Tooltip title="Add leave record">
                <Button type="primary" icon={<i className="fal fa-plus" />} onClick={handleAddLeaveRecord} />
              </Tooltip>
            )}
            <Tooltip title="Download excel">
              <Button
                icon={<i className="fal fa-arrow-down-to-bracket" />}
                onClick={handleDownloadClick}
                loading={downloading}
              />
            </Tooltip>
            <ViewSelectionButton
              screenCode={SCREEN_CODE}
              viewId={viewId}
              title="Configure leave record columns"
              onClose={handleCloseSelectionDrawer}
            />
          </Space>
        </div>
        <Card fitParent table>
          <Table
            rowKey="id"
            dataSource={data?.data}
            columns={columns}
            fitParent
            loading={dataLoading || viewLoading}
            scroll={{ x: tableWidth, y: 1000 }}
            pagination={{
              total: data?.count,
              current: page,
              pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showSizeChanger: true,
              onChange: handlePaginationChange,
              style: paginationStyle
            }}
          />
        </Card>
      </div>
      <MutateLeaveRecordDrawer {...drawerState} onClose={handleCloseDrawer} />
    </div>
  )
}
