import React, { FC, useCallback, useEffect } from 'react'
import moment from 'moment-timezone'
import { ColumnsType, Drawer, Table } from '~/core-components'
import { dispatch } from '~/stores/store'
import { TimeLogState } from '../../../types'
import { useTimeLogsByDaily, useDailyRecord, useLocationsDict } from '../../../hooks'
import { refetchTimeLogsByDaily } from '../../../reducers'

export interface ViewTimeLogsDrawerProps {
  dailyRecordId: string
  visible: boolean
  onClose: () => void
}

export const ViewTimeLogsDrawer: FC<ViewTimeLogsDrawerProps> = ({ dailyRecordId, visible, onClose }) => {
  const [dailyRecord] = useDailyRecord(dailyRecordId)
  const [data, loading] = useTimeLogsByDaily(dailyRecordId, 'when-empty')
  const [locationDict] = useLocationsDict()

  useEffect(() => {
    if (visible) {
      dispatch(refetchTimeLogsByDaily({ dailyRecordId }))
    }
  }, [visible, dailyRecordId])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const columns: ColumnsType<TimeLogState> = [
    {
      title: 'Start',
      key: 'clockInTime',
      dataIndex: 'clockInTime',
      render: (value: string) => {
        if (!value) return ''

        const momentValue = moment(value)
        if (momentValue.isSame(dailyRecord?.clockDate, 'date')) {
          return momentValue.format('HH:mm')
        } else {
          return (
            <>
              {momentValue.format('HH:mm')} on {momentValue.format('DD MMM YYYY (ddd)')}
            </>
          )
        }
      }
    },
    {
      title: 'End',
      key: 'clockOutTime',
      dataIndex: 'clockOutTime',
      render: (value: string) => {
        if (!value) return ''

        const momentValue = moment(value)
        if (momentValue.isSame(dailyRecord?.clockDate, 'date')) {
          return momentValue.format('HH:mm')
        } else if (momentValue.isSame(dailyRecord?.clockDate, 'year')) {
          return `${momentValue.format('HH:mm')} on ${momentValue.format('DD MMM (ddd)')}`
        } else {
          return `${momentValue.format('HH:mm')} on ${momentValue.format('DD MMM YYYY (ddd)')}`
        }
      }
    },
    {
      title: 'Location',
      key: 'locationId',
      dataIndex: 'locationId',
      render: (value: string) => locationDict[value]?.name
    },
    {
      title: 'Notes',
      key: 'notes',
      dataIndex: 'notes'
    }
  ]

  return (
    <Drawer
      open={visible}
      title="Time logs"
      onClose={() => handleCloseDrawer()}
      width={700}
      className="view-time-logs-drawer"
    >
      <Table rowKey="id" dataSource={data} pagination={false} columns={columns} loading={loading} />
    </Drawer>
  )
}
