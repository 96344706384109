import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { setKeyvalue } from '~/features/master'
import { apiAddAttendanceGroup } from '../api/attendance-group.api'
import { setAttendanceGroup } from '../reducers'
import { AttendanceGroupState, IAttendanceGroupInfo } from '../types'

export const addAttendanceGroup = (attendanceGroup: IAttendanceGroupInfo): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddAttendanceGroup(attendanceGroup)
    if (status) {
      const newAttendanceGroup: AttendanceGroupState = {
        ...attendanceGroup,
        id: result.id
      }
      dispatch(setAttendanceGroup(newAttendanceGroup))
      dispatch(
        setKeyvalue({
          kv: 'attendanceGroup',
          data: { key: result.id, value: newAttendanceGroup.name, inactiveDate: newAttendanceGroup.inactiveDate }
        })
      )
      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
