export class EmGroup {
  static get gender() {
    return 'employee.gender'
  }
  static get employmentType() {
    return 'em_employment_type.employment_type_id'
  }
  static get department() {
    return 'em_department.department_id'
  }
  static get maritalStatus() {
    return 'employee.marital_status'
  }
  static get race() {
    return 'employee.race_id'
  }
  static get religion() {
    return 'employee.religion_id'
  }
  static get nationality() {
    return 'employee.nationality_id'
  }
  static get company() {
    return 'em_company.company_id'
  }
  static get job() {
    return 'em_job.job_id'
  }
  static get office() {
    return 'em_office.office_id'
  }
  static get costCentre() {
    return 'em_cost_centre.cost_centre_id'
  }
  static get calendar() {
    return 'em_calendar.calendar_id'
  }
}

export class HiringType {
  static get newHire() {
    return 'new_hire'
  }
  static get rehire() {
    return 'rehire'
  }
  static get transfer() {
    return 'transfer'
  }
}

export class WorkSchedule {
  static get calendar() {
    return 'calendar'
  }
  static get schedule() {
    return 'schedule'
  }
}

export class EmploymentConfig {
  static get department() {
    return 'department'
  }
  static get division() {
    return 'division'
  }
  static get section() {
    return 'section'
  }
  static get grp() {
    return 'grp'
  }
  static get category() {
    return 'category'
  }
  static get team() {
    return 'team'
  }
  static get job() {
    return 'job'
  }
  static get manager() {
    return 'manager'
  }
  static get managerSecondary() {
    return 'manager_secondary'
  }
  static get office() {
    return 'office'
  }
  static get costCentre() {
    return 'cost_centre'
  }
  static get employmentType() {
    return 'employment_type'
  }
  static get otClass() {
    return 'ot_class'
  }
}

export class EmInfoType {
  static get personal() {
    return 'personal'
  }
  static get emergency() {
    return 'emergency'
  }
  static get location() {
    return 'location'
  }
}

export enum EmStatus {
  Active = 'active'
}

export class SelfUpdateConfig {
  static get personal() {
    return 'personal'
  }
  static get identity() {
    return 'identity'
  }
  static get address() {
    return 'address'
  }
  static get family() {
    return 'family'
  }
  static get emergency() {
    return 'emergency'
  }
  static get education() {
    return 'education'
  }
}
export class MovementField {
  static get location() {
    return 'location'
  }
}

export enum SysImportField {
  BADGE_NO = '8cd402d5-5678-4eff-b4a5-f16720478ac4',
  CLOCK_IN_TIME = '66c2483d-2429-4cf2-8c26-813a6aaf5df0',
  CLOCK_OUT_TIME = '8b1e8c13-aae8-4b70-bf33-4f2a94428bcb'
}

export enum CpfType {
  CITIZEN = 'sc',
  GRADUATED = 'gg'
}
