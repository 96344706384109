import { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { AlignType, Button, Card, ColumnsType, Form, Space, Table, Tooltip } from '~/core-components'
import { Person, SearchInput } from '~/components'
import {
  Screen,
  ViewCriteriaSimple,
  ViewCriteria,
  updateViewCriteria,
  useViewSchema,
  ViewSelectionButton,
  useFirstView
} from '~/features/selection'
import { usePermissionGate } from '~/features/iam'
import { StoreState } from '~/types/store'
import { Permission } from '~/constants'
import { dispatch } from '~/stores/store'
import { EMP_ROUTES } from '~/routes/routes'
import { useIsMountedRef } from '~/hooks'
import {
  formatYearMonth,
  formatMonth,
  formatMoney,
  getBaseUrl,
  getFileTimestamp,
  downloadWithDom,
  showError
} from '~/utils'
import { selectDailyFields, selectDailySummaryView } from '../../selectors'
import { fetchDailySummaryView } from '../../actions'
import { refetchDailySummaryView } from '../../reducers'
import { DailySummaryRowState } from '../../types'
import { useAttendancePeriod } from '../../hooks'
import { apiGetDailySummaryExcel } from '../../api/daily-summary.api'
import { DailyRecordsByEmployee } from './components/DailyRecordsByEmployee'
import './DailySummary.less'

interface DailySummaryProps {
  attendancePeriodId: string
}

const nameColWidth = 250
const dateTimeColWidth = 250
const defaultColWidth = 150
const paginationStyle: CSSProperties = { marginRight: 20 }

const SCREEN_CODE: Screen = 'daily_summary'
const PAGE_SIZE_OPTIONS = ['20', '50', '100']
const baseUrl = getBaseUrl('/filestore')

const START_DATE = moment().startOf('month').format('YYYY-MM-DD')
const END_DATE = moment().endOf('month').format('YYYY-MM-DD')

export const DailySummary: FC<DailySummaryProps> = ({ attendancePeriodId }) => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [search, setSearch] = useState<string>('')

  const [attendancePeriod] = useAttendancePeriod(attendancePeriodId, 'when-empty')
  const startDate = attendancePeriod?.startDate || START_DATE
  const endDate = attendancePeriod?.endDate || END_DATE

  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const selection = useSelector((state: StoreState) => state.selection.sysSelectionFields[SCREEN_CODE])
  const [schema] = useViewSchema(SCREEN_CODE, viewId)
  const dataLoading = useSelector((state: StoreState) => state.attendance.dailySummaryViewLoading)
  const data = useSelector(selectDailySummaryView)(viewId || '')
  const refetch = useSelector((state: StoreState) => state.attendance.dailySummaryViewRefetch)
  const processing = useSelector((state: StoreState) => state.attendance.dailyProcessing)

  const dailyFields = useSelector(selectDailyFields)
  const canViewEmployee = usePermissionGate(Permission.employee)

  const isMountedRef = useIsMountedRef()
  const [downloading, setDownloading] = useState(false)

  useEffect(() => {
    if (viewId && !processing) {
      dispatch(
        fetchDailySummaryView(viewId, attendancePeriodId, { offset: pageSize * (page - 1), limit: pageSize }, search)
      )
    }
  }, [viewId, attendancePeriodId, page, pageSize, search, processing, refetch])

  const tableWidth = useMemo(() => {
    return (
      (schema?.selection
        .filter(f => selection?.ids.includes(f.selectionFieldId))
        .reduce((sum, f) => (sum += selection?.entities[f.selectionFieldId]?.width || defaultColWidth), 0) || 0) +
      nameColWidth +
      dateTimeColWidth
    )
  }, [schema, selection])

  const columns = useMemo(() => {
    let columns: ColumnsType<DailySummaryRowState> = [
      {
        title: 'Name',
        key: 'employeeId',
        dataIndex: 'employeeId',
        fixed: 'left',
        className: 'first-col',
        width: nameColWidth,
        onCell: (record, index) => {
          const prevId = data.data[(index as number) - 1]?.employeeId
          if (prevId === record.employeeId) {
            return { rowSpan: 0 }
          } else {
            const nextId = data.data[(index as number) + 1]?.employeeId
            if (nextId === record.employeeId) {
              return { rowSpan: record.rowSpan }
            }
          }
          return {}
        },
        render: (_, record) => (
          <Person
            name={record.employeeName}
            description={record.description}
            photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/36`}
            path={canViewEmployee ? EMP_ROUTES.employee.replace(':id', record.employeeId) : undefined}
            size={36}
          />
        )
      }
    ]

    if (schema) {
      // Configurable employee columns
      columns.push(
        ...schema?.selection
          .filter(f => selection?.ids.includes(f.selectionFieldId))
          .map(f => {
            const field = selection?.entities[f.selectionFieldId]
            const align = field?.format === 'money' ? 'right' : ('left' as AlignType)

            return {
              title: field?.description,
              key: field?.fieldName,
              dataIndex: field?.fieldName,
              width: field?.width || defaultColWidth,
              align,
              onCell: (record: DailySummaryRowState, index?: number) => {
                if (dailyFields.includes(field?.fieldName || '')) {
                  return {}
                }

                const prevId = data.data[(index as number) - 1]?.employeeId
                if (prevId === record.employeeId) {
                  return { rowSpan: 0 }
                } else {
                  const nextId = data.data[(index as number) + 1]?.employeeId
                  if (nextId === record.employeeId) {
                    return { rowSpan: record.rowSpan }
                  }
                }
                return {}
              },
              render: (value: string | number | boolean, record: DailySummaryRowState, index: number) => {
                var display = value
                if (field?.format === 'date' && value) {
                  display = moment(value as string).format('DD MMM YYYY')
                }
                if (field?.format === 'yearmonth' && value) {
                  display = formatYearMonth(value as string)
                }
                if (field?.format === 'month' && value) {
                  display = formatMonth(value as number)
                }
                if (field?.format === 'money' && value) {
                  display = formatMoney(value as number)
                }
                if (field?.format === 'yes_no') {
                  display = (value as boolean) ? 'Yes' : 'No'
                }

                return display
              }
            }
          })
      )
    }

    return columns
  }, [schema, canViewEmployee, dailyFields, selection, data.data])

  const handleDownloadClick = useCallback(async () => {
    if (viewId) {
      try {
        setDownloading(true)
        const { status, result, errors, message, errorData } = await apiGetDailySummaryExcel(
          viewId,
          attendancePeriodId,
          {
            search
          }
        )

        if (status) {
          const fileName = `daily_summary_${getFileTimestamp()}.xlsx`
          downloadWithDom(result, fileName)
        } else {
          console.error('Error while downloading', errors)
          showError(message, errorData)
        }
      } finally {
        if (isMountedRef.current) setDownloading(false)
      }
    }
  }, [viewId, search, attendancePeriodId, isMountedRef])

  const handleCloseSelectionDrawer = useCallback((changed?: boolean) => {
    if (changed) dispatch(refetchDailySummaryView())
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchDailySummaryView())
      }
    },
    [viewId]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleSearch = useCallback((value: string) => {
    setPage(1)
    setSearch(value)
  }, [])

  return (
    <div className="daily-summary">
      <div className="daily-summary__body">
        <div className="daily-summary__action-bar">
          <Space>
            <Form.Item label="">
              <SearchInput onSearch={handleSearch} />
            </Form.Item>
          </Space>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          <Space align="start">
            <Tooltip title="Download excel">
              <Button
                icon={<i className="fal fa-arrow-down-to-bracket" />}
                onClick={handleDownloadClick}
                loading={downloading}
              />
            </Tooltip>
            <ViewSelectionButton
              screenCode={SCREEN_CODE}
              viewId={viewId || ''}
              title="Configure daily record columns"
              onClose={handleCloseSelectionDrawer}
            />
          </Space>
        </div>
        <Card fitParent table>
          <Table
            rowKey="employeeId"
            dataSource={data?.data}
            columns={columns}
            fitParent
            loading={dataLoading || viewLoading}
            scroll={{ x: tableWidth, y: 1000 }}
            pagination={{
              total: data?.count,
              current: page,
              pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showSizeChanger: true,
              onChange: handlePaginationChange,
              style: paginationStyle
            }}
            expandable={{
              expandedRowRender: (record: DailySummaryRowState) => (
                <DailyRecordsByEmployee
                  viewId={viewId}
                  employeeId={record.employeeId}
                  startDate={startDate}
                  endDate={endDate}
                />
              )
            }}
          />
        </Card>
      </div>
    </div>
  )
}
