import React, { CSSProperties, FC, useCallback, useMemo } from 'react'
import { Form, Link, SecondaryText, Skeleton, Space } from '~/core-components'
import { AmountDisplay, Col, Row, SubHeader } from '~/components'
import { EmPublicPerson } from '~/features/employee'
import { useClaimType } from '~/features/claim'
import { ClaExpenseSubmissionType } from '~/constants'
import { SSClaimTaskState } from '../../../types'
import { useMyClaimRecordAttachments } from '../../../hooks'
import { downloadWithDom, getFileTimestamp, showError } from '~/utils'
import { MyClaimTaskSingleForm } from './MyClaimTaskSingleForm'
import { MyClaimTaskMultipleFormRow } from './MyClaimTaskMultipleFormRow'
import { apiDownloadAllMyClaimAttachment } from '~/features/my-claim/api/ss-claim-record.api'
import './MyClaimTaskForm.less'

interface MyClaimTaskFormProps {
  data: SSClaimTaskState | undefined
  loading: boolean
}

const taxStyle: CSSProperties = { fontWeight: 'normal', fontStyle: 'italic' }

export const MyClaimTaskForm: FC<MyClaimTaskFormProps> = ({ data, loading }) => {
  const [claimType] = useClaimType(data?.claimTypeId || '', 'when-empty')
  const isMultiple = claimType?.expenseSubmissionType === ClaExpenseSubmissionType.Multiple
  const [attachments] = useMyClaimRecordAttachments(data?.id)
  const isExpenseTaxable = useMemo(() => data?.expenses?.some(exp => exp.isTaxable), [data?.expenses])

  const handleDownloadAllAttachment = useCallback(async () => {
    const { status, result, errors, message, errorData } = await apiDownloadAllMyClaimAttachment(data?.id || '')

    if (status) {
      const fileName = `claim_attachment_${data?.claimNo}_${getFileTimestamp()}.zip`
      downloadWithDom(result, fileName)
    } else {
      console.error('Error while downloading', errors)
      showError(message, errorData)
    }
  }, [data])

  return (
    <div className="my-claim-task-form">
      {!data && !loading && <div className="my-claim-task-form__empty">Not found</div>}
      {data && (
        <Form>
          <Row>
            <Col span={16}>
              <Form.Item>
                <EmPublicPerson id={data.employeeId} />
              </Form.Item>
            </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
              {attachments.length > 0 && <Link onClick={handleDownloadAllAttachment}>download all attachments</Link>}
            </Col>
          </Row>
          <Row gutter={[15, 10]}>
            <Col span={24} style={{ fontSize: 12, fontWeight: 'bold' }}>
              <Form.Item>{data.claimNo ? `Claim #${data.claimNo}` : ''}</Form.Item>
            </Col>
          </Row>
          <Row gutter={[15, 10]}>
            <Col span={isMultiple ? 12 : 24}>
              <Form.Item label="Claim type">
                <Space>
                  {claimType?.icon && <i className={`fal ${claimType?.icon}`} />}
                  {claimType?.name}
                </Space>
              </Form.Item>
            </Col>
            {isMultiple && (
              <Col span={12}>
                <Form.Item label="Notes">{data.notes || '-'}</Form.Item>
              </Col>
            )}
          </Row>
          {!isMultiple && <MyClaimTaskSingleForm data={data} attachments={attachments} />}
          {isMultiple &&
            (loading ? (
              <Skeleton active />
            ) : (
              <>
                {data.expenses?.map((exp, index) => (
                  <>
                    <MyClaimTaskMultipleFormRow
                      key={exp.id}
                      index={index}
                      data={exp}
                      claimRecordId={data.id}
                      claimTypeId={data.claimTypeId}
                      attachments={attachments}
                    />
                    {!(index === data.expenses.length - 1) && (
                      <div className="expense-divider">
                        <hr />
                      </div>
                    )}
                  </>
                ))}
                <Row className="my-claim-task-form__claim-amount">
                  <Col span={24}>
                    <SubHeader type="primary">
                      <Row>
                        <Col flex="auto">Total claim amount</Col>
                        <Col flex="none">
                          <AmountDisplay symbol={claimType?.currencyCode} value={data.claimAmount} />
                        </Col>
                      </Row>
                      {isExpenseTaxable && (
                        <Row>
                          <Col flex="auto" />
                          <Col flex="none">
                            <SecondaryText size="small" style={taxStyle}>
                              Inclusive of GST{' '}
                              <AmountDisplay symbol={claimType?.currencyCode} value={data.claimAmountTax} />
                            </SecondaryText>
                          </Col>
                        </Row>
                      )}
                    </SubHeader>
                  </Col>
                </Row>
              </>
            ))}
        </Form>
      )}
    </div>
  )
}
